import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Links from 'components/layout/Sections/Pages/Wiadomosci/Links'
import Tiles1 from 'components/layout/Posts/PostsTiles1'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Wiadomości',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Wiadomości',
    },
  ],
}

const links = [
  {
    label: 'Aktualności',
    link: '/wiadomosci/aktualnosci/',
  },
  {
    label: 'Porady i trendy',
    link: '/wiadomosci/porady-i-trendy/',
  },
  {
    label: 'Media o nas',
    link: '/wiadomosci/media-o-nas/',
  },
  {
    label: 'Działalność społeczna',
    link: '/wiadomosci/dzialalnosc-spoleczna/',
  },
]

const WiadomosciPage: React.FC<PageProps> = ({ data }) => {
  //   const PAGE_SEO = data?.wpPage?.seo

  const GALLERY1 = React.useMemo(
    () => [
      {
        img: data?.newestNewsPost.edges[0].node.artykul.articleArticleImg
          .localFile.childImageSharp.gatsbyImageData,
        alt: 'news',
        link: `/wiadomosci/aktualnosci/${data?.newestNewsPost.edges[0].node.slug}/`,
        badge1Text: 'Aktualności',
        badge2Text: data?.newestNewsPost.edges[0].node.title,
      },
      {
        img: data?.newestTrendsPost.edges[0].node.artykul.articleArticleImg
          .localFile.childImageSharp.gatsbyImageData,
        alt: 'advices',
        link: `/wiadomosci/porady-i-trendy/${data?.newestTrendsPost.edges[0].node.slug}/`,
        badge1Text: 'Porady i trendy',
        badge2Text: data?.newestTrendsPost.edges[0].node.title,
      },
      {
        img: data?.newestMediaPost.edges[0].node.artykul.articleArticleImg
          .localFile.childImageSharp.gatsbyImageData,
        alt: 'media-about-us',
        link: `/wiadomosci/media-o-nas/${data?.newestMediaPost.edges[0].node.slug}/`,
        badge1Text: 'Media o nas',
        badge2Text: data?.newestMediaPost.edges[0].node.title,
      },
      {
        img: data?.newestSocialActivityPost.edges[0].node.artykul
          .articleArticleImg.localFile.childImageSharp.gatsbyImageData,
        alt: 'social-activity',
        link: `/wiadomosci/dzialalnosc-spoleczna/${data?.newestSocialActivityPost.edges[0].node.slug}/`,
        badge1Text: 'Działalność społeczna',
        badge2Text: data?.newestSocialActivityPost.edges[0].node.title,
      },
    ],
    []
  )

  return (
    <Layout>
      <SEO
        title="Gatigo ⇒ Wiadomości"
        description="Gatigo ⇒ Wiadomości"
        // ogTitle={PAGE_SEO.opengraphTitle}
        // ogDescription={PAGE_SEO.opengraphDescription}
        // ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        // twitterTitle={PAGE_SEO.twitterTitle}
        // twitterDescription={PAGE_SEO.twitterDescription}
        // twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={data?.heroImgMobile?.childImageSharp?.gatsbyImageData}
        imgDesktop={data?.heroImgMobile?.childImageSharp?.gatsbyImageData}
        headingMobile="Wiadomości"
        headingDesktop="Wiadomości"
        breadcrumbs={breadcrumbs}
      />
      <Links links={links} />
      <Tiles1 heading="Najnowsze wiadomości" gallery={GALLERY1} />
    </Layout>
  )
}

export default WiadomosciPage

export const query = graphql`
  query WiadomosciPage {
    heroImgMobile: file(name: { eq: "hero-img-18" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    newestNewsPost: allWpAcrtile(
      sort: { order: DESC, fields: date }
      filter: { artykul: { articleArticleTyp: { eq: "news" } } }
      limit: 1
    ) {
      edges {
        node {
          date
          title
          slug
          artykul {
            articleArticleTyp
            articleArticleImg {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [JPG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
    newestTrendsPost: allWpAcrtile(
      sort: { order: DESC, fields: date }
      filter: { artykul: { articleArticleTyp: { eq: "trends" } } }
      limit: 1
    ) {
      edges {
        node {
          date
          title
          slug
          artykul {
            articleArticleTyp
            articleArticleImg {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [JPG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
    newestMediaPost: allWpAcrtile(
      sort: { order: DESC, fields: date }
      filter: { artykul: { articleArticleTyp: { eq: "media" } } }
      limit: 1
    ) {
      edges {
        node {
          date
          title
          slug
          artykul {
            articleArticleTyp
            articleArticleImg {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [JPG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
    newestSocialActivityPost: allWpAcrtile(
      sort: { order: DESC, fields: date }
      filter: { artykul: { articleArticleTyp: { eq: "csr" } } }
      limit: 1
    ) {
      edges {
        node {
          date
          title
          slug
          artykul {
            articleArticleTyp
            articleArticleImg {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [JPG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
